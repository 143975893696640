import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import get from 'lodash/get'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { AngleUp } from "@styled-icons/fa-solid/AngleUp"
import { Description } from "@styled-icons/material/Description"
import { Help } from "@styled-icons/material/Help"
import { Casino } from "@styled-icons/material/Casino"
import { Payment } from "@styled-icons/material/Payment"
import { Info } from "@styled-icons/material/Info"
import { Person } from "@styled-icons/material/Person"
import { Menu } from "@styled-icons/material/Menu"

class KasinoTemplate extends React.Component {
  componentDidMount(){
    // EXTERNAL LINKS _blank
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname !== window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
    // FAQ - accordions
    var collapse = document.getElementsByClassName("accordion-title");
    var c;
    for (c = 0; c < collapse.length; c++) {
    collapse[c].addEventListener("click", function() {
        this.classList.toggle("active");
        this.nextElementSibling.classList.toggle("show");  
    })
    }
    // SIDEBAR SCROLLS
    let scrollpos = window.scrollY
    const header = document.getElementById("fixed")
    const header_height = header.offsetHeight
    const add_class_on_scroll = () => header.classList.add("show")
    const remove_class_on_scroll = () => header.classList.remove("show")
    window.addEventListener('scroll', function() { 
      scrollpos = window.scrollY;
      if (scrollpos >= header_height) { add_class_on_scroll() }
      else { remove_class_on_scroll() }
    })
  }
  render() {
    const kasino = get(this.props, 'data.kasino')
    const similar = get(this.props, 'data.allWpCptKasino')
  return (
    <Layout bodyClass={`page-template-default page page-id-${kasino.databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
      <GatsbySeo 
        title={kasino.seo.title} description={kasino.seo.metaDesc} 
        canonical={`https://www.vetoveljet.com/kokemuksia/${kasino.slug}/`}
      />
      <article className={`casino-${kasino.databaseId} kokemuksia page type-page status-publish hentry`} id={`casino-${kasino.databaseId}`}>
        <header className="casino-header">
          <div className="header-content">
            <div className="top">
              <h1 className="entry-title">{kasino.ACFKasino.kasinonNimi}</h1>
              <div className="logo">
                <img alt={kasino.ACFKasino.logo.title} src={kasino.ACFKasino.logo.mediaItemUrl} className="logoImg" width="130" height="54"/>
              </div>
              <div className="arvostelu">
                {kasino.ACFKasino.arvostelu === 1 && <img src="/images/star1.png" alt='Arvostelu 1/5' width="21" height="20"/>}
                {kasino.ACFKasino.arvostelu === 2 && <img src="/images/star2.png" alt='Arvostelu 2/5' width="42" height="20"/>}
                {kasino.ACFKasino.arvostelu === 3 && <img src="/images/star3.png" alt='Arvostelu 3/5' width="63" height="20"/>}
                {kasino.ACFKasino.arvostelu === 4 && <img src="/images/star4.png" alt='Arvostelu 4/5' width="84" height="20"/>}
                {kasino.ACFKasino.arvostelu === 5 && <img src="/images/star5.png" alt='Arvostelu 5/5' width="105" height="20"/>}
                {kasino.ACFKasino.arvostelu == null && <span>-</span>}
              </div>
            </div>
            <div className="center">
              <div className="text" dangerouslySetInnerHTML={{ __html: kasino.ACFKasino.lyhytbonuskuvaus}} />
              <div className="why" dangerouslySetInnerHTML={{ __html: kasino.ACFKasino.plussat}} />
            </div>
            <div className="bottom">
              <div className="offers">
                <div className="item">
                  <p>Bonus vedonlyöntiin:</p>
                  <div className="offer">{kasino.ACFKasino.bonusVedonloyntiin}<i>Bonusrahaa</i></div>
                </div>
                <div className="item">
                  <p>Bonus casinolle: </p>              
                  <div className="offer">{kasino.ACFKasino.bonusKasino}<i>Bonusrahaa</i></div>
                  <div className="offer">{kasino.ACFKasino.ilmaiskierrokset}<i>Ilmaiskierroksia</i></div>
                </div>
              </div>
              {kasino.ACFKasino.kasinoSuljettu == true 
              ?<div className="closed">Kasino suljettu</div>
              :<a className="btn" href={kasino.ACFKasino.affiliateVedonlyonti} target="_blank" rel="noopener noreferrer">Siirry sivustolle</a>
              }
            </div>
          </div>
        </header>
        {kasino.ACFKasino.saantoteksti != null &&
          <div className="saannot" dangerouslySetInnerHTML={{ __html: kasino.ACFKasino.saantoteksti}}/>
        }
        <div className="sidebar">
          <div className="pageNav">
            <h5>Siirry:</h5>
            <div className="drop"><Menu className="icon material-icons"/></div>
            <ul className="anchor">
              <li><a href="#bonukset"><Casino className="icon material-icons"/>Bonukset</a></li>
              <li><a href="#yleisesti"><Info className="icon material-icons"/>Yleistiedot</a></li>
              <li><a href="#talletukset"><Payment className="icon material-icons"/>Talletukset</a></li>      
              <li><a href="#faq"><Help className="icon material-icons"/>FAQ</a></li>  
              <li><a href="#arvostelu"><Description className="icon material-icons"/>Esittely ja arvostelu</a></li>   
              <li><a href="#arvostelija"><Person className="icon material-icons"/>Arvostelijan kokemuksia</a></li> 
              <li><a href="#tuotevalikoima"><Description className="icon material-icons"/>Tuotevalikoima</a></li>        
              <li><a href="#pelinvalmistajat"><Casino className="icon material-icons"/>Pelinvalmistajat</a></li>
            </ul>
          </div>
        </div>
        <div className="content">
          <h2 id="bonukset">Bonus vedonlyöntiin</h2>
          <div className="text" dangerouslySetInnerHTML={{ __html: kasino.ACFKasino.vedonlyontibonuskuvaus}}/>
          {kasino.ACFKasino.kasinoSuljettu == true 
          ?null
          :<>{kasino.ACFKasino.vedonlyontiCtabtn != null && kasino.ACFKasino.vedonlyontiCtabtn.toString() === 'true'  ?<a className="bonusBtn" href={kasino.ACFKasino.affiliateVedonlyonti} target="_blank" rel="noreferrer">{kasino.ACFKasino.vedonlyontiCtateksti}</a>:null}</>
          }
          <h2>Bonus kasinopelaajille</h2>
          <div className="text" dangerouslySetInnerHTML={{ __html: kasino.ACFKasino.casinobonuskuvaus}}/>
          {kasino.ACFKasino.kasinoSuljettu == true 
          ?null
          :<>{kasino.ACFKasino.casinobonusCtabtn != null && kasino.ACFKasino.casinobonusCtabtn.toString() === 'true' ?<a className="bonusBtn" href={kasino.ACFKasino.affiliateCasinobonus} target="_blank" rel="noreferrer">{kasino.ACFKasino.casinobonusCtateksti}</a>:null}</>
          }
          <hr/>
          <h2 id="yleisesti">Pelipaikan yleistiedot</h2>
          <div className="boxContent">
            <div className="element">
              <span>Omistaja:</span>
              <b>{kasino.ACFKasino.omistaja}</b>
            </div>
            <div className="element">
              <span>Perustamisvuosi:</span>
              <b>{kasino.ACFKasino.perustamisvuosi}</b>
            </div>
            <div className="element">
              <span>Lisenssi:</span>
              <b>{kasino.ACFKasino.lisenssi}</b>
            </div>
            <div className="element">
              <span>Kotisivut:</span>
              {(kasino.ACFKasino.kotisivut !== null && kasino.ACFKasino.kasinoSuljettu != true)
              ?<b><a href={kasino.ACFKasino.affiliateVedonlyonti} target="_blank" rel="noreferrer noopener">{kasino.ACFKasino.kotisivut}</a></b>
              :<b>{kasino.ACFKasino.kotisivut}</b>}
              {kasino.ACFKasino.kotisivut == null && <b>-</b>}
            </div>
          </div>
          <div className="boxContent">
          <div className="bonus element">
              <span>Bonus vedonlyöntiin:</span>
              <b>{kasino.ACFKasino.bonusVedonloyntiin}</b>
            </div>
            <div className="bonus element">
              <span>Bonus casinolle:</span>
              <b>{kasino.ACFKasino.bonusKasino} + {kasino.ACFKasino.ilmaiskierrokset}</b>            
            </div>
            <div className="bonus element">
              <span>Email:</span>
              {kasino.ACFKasino.kasinonEmail !== null
              ?<b><a href={`mailto:${kasino.ACFKasino.kasinonEmail}`}>{kasino.ACFKasino.kasinonEmail}</a></b>
              :<b>-</b>
              }
            </div>
            <div className="bonus element">
              <span>Puhelin:</span>
              {kasino.ACFKasino.kasinonPuhelin !== null
              ?<b><a href={`tel:${kasino.ACFKasino.kasinonPuhelin}`}>{kasino.ACFKasino.kasinonPuhelin}</a></b>
              :<b>-</b>
              }
            </div>
          </div>
          <div className="boxContent">
            <div className="bool element">
              <span>Livechat: </span>
              <span className="boolean">
              {kasino.ACFKasino.livechat != null && kasino.ACFKasino.livechat.toString() === "true" ?<img src="/images/true.png" alt="true icon" className="lazyload" width="15" height="15"/>:null}
                {kasino.ACFKasino.livechat == null || kasino.ACFKasino.livechat.toString() === "false" ?<img src="/images/false.png" alt="false icon" className="lazyload" width="15" height="15"/>:null}
              </span>
            </div>
            <div className="bool element">
              <span>Sivut käännetty: </span>
              <span className="boolean">
              {kasino.ACFKasino.sivutSuomeksi != null && kasino.ACFKasino.sivutSuomeksi.toString() === "true" ?<img src="/images/true.png" alt="true icon" className="lazyload" width="15" height="15"/>:null}
                {kasino.ACFKasino.sivutSuomeksi == null || kasino.ACFKasino.sivutSuomeksi.toString() === "false" ?<img src="/images/false.png" alt="false icon" className="lazyload" width="15" height="15"/>:null}
              </span>
            </div>
            <div className="bool element">
              <span>Asiakaspalvelu suomeksi: </span>
              <span className="boolean">
              {kasino.ACFKasino.asiakaspalveluSuomeksi != null && kasino.ACFKasino.asiakaspalveluSuomeksi.toString() === "true" ?<img src="/images/true.png" alt="true icon" className="lazyload" width="15" height="15"/>:null}
                {kasino.ACFKasino.asiakaspalveluSuomeksi == null || kasino.ACFKasino.asiakaspalveluSuomeksi.toString() === "false" ?<img src="/images/false.png" alt="false icon" className="lazyload" width="15" height="15"/>:null}
              </span>
            </div>
            <div className="bool element">
              <span>Suomi kampanjat: </span>
              <span className="boolean">
                {kasino.ACFKasino.suomiKampanjat != null && kasino.ACFKasino.suomiKampanjat.toString() === "true" ?<img src="/images/true.png" alt="true icon" className="lazyload" width="15" height="15"/>:null}
                {kasino.ACFKasino.suomiKampanjat == null || kasino.ACFKasino.suomiKampanjat.toString() === "false" ?<img src="/images/false.png" alt="false icon" className="lazyload" width="15" height="15"/>:null}
              </span>
            </div>
          </div>
          <hr/>
          <h2 id="talletukset">Talletukset ja kotiutukset</h2>
          <div className="talletus">{kasino.ACFKasino.talletus.map(function(r, idx) {
            return(<img key={idx} src={`/images/maksu/${r.replace(" ", "").toLowerCase()}.png`} alt={r} width="77" height="25"/>)
          })}
          </div>
          <hr/>
          <h2 id="faq">FAQ: Usein kysytyt kysymykset</h2>
          <div className="accordions">
            <div className="accordion">
              <div className="accordion-title kasino"><h5>Onko {kasino.ACFKasino.kasinonNimi} luotettava ja turvallinen pelipaikka?</h5><AngleUp className="material-icons"/></div>
              <p className="accordion-answer">{kasino.ACFKasino.faq1}</p>
            </div>
            <div className="accordion">
              <div className="accordion-title kasino"><h5>Millä tavoilla talletukset ja kotiutukset onnistuvat {kasino.ACFKasino.kasinonNimi} -sivustolla?</h5><AngleUp className="material-icons"/></div>
              <p className="accordion-answer">{kasino.ACFKasino.faq2}</p>
            </div>
            <div className="accordion">
              <div className="accordion-title kasino"><h5>Minkä lisenssin kautta {kasino.ACFKasino.kasinonNimi} operoi?</h5> <AngleUp className="material-icons"/></div>
              <p className="accordion-answer">{kasino.ACFKasino.faq3}</p>
            </div>
            <div className="accordion">
              <div className="accordion-title kasino"><h5>Millaisia kokemuksia pelaajilla on {kasino.ACFKasino.kasinonNimi} -sivustosta?</h5><AngleUp className="material-icons"/></div>
              <p className="accordion-answer">{kasino.ACFKasino.faq4}</p>
            </div>
            <div className="accordion">
              <div className="accordion-title kasino"><h5>Kuinka voin avata pelitilin {kasino.ACFKasino.kasinonNimi} -sivustolle?</h5><AngleUp className="material-icons"/></div>
              <p className="accordion-answer">{kasino.ACFKasino.faq5}</p>
            </div>
            <div className="accordion">
              <div className="accordion-title kasino"><h5>Miten asiakaspalvelu toimii {kasino.ACFKasino.kasinonNimi} -sivustolla?</h5><AngleUp className="material-icons"/></div>
              <p className="accordion-answer">{kasino.ACFKasino.faq6}</p>
            </div> 
          </div>
          <hr/>
          <h2 id="arvostelu">{kasino.ACFKasino.kasinonNimi} - Esittely ja arvostelu</h2>
          <img src={kasino.ACFKasino.screenshot.mediaItemUrl} alt={kasino.ACFKasino.screenshot.title} width="370" height="180" className="textImg"/>
          <div className="text" dangerouslySetInnerHTML={{ __html: kasino.ACFKasino.arvosteluteksti}}/>
          <hr/>
          <h2 id="arvostelija">Arvostelijan kokemuksia: {kasino.ACFKasino.kasinonNimi} vedonlyöjän silmin</h2>
          <div className="text" dangerouslySetInnerHTML={{ __html: kasino.ACFKasino.arvostelijanKokemukset}}/>
          <hr/>
          <h2 id="tuotevalikoima">Tarjolla olevat pelikategoriat</h2>
          <ul>{kasino.ACFKasino.tuotevalikoima.map(function(g, idx) {
            return(<li key={idx}>{g}</li>)
          })}</ul>
          <hr/>
          <h2 id="pelinvalmistajat">Pelinvalmistajat casinolla</h2>
          <ul>{kasino.ACFKasino.pelinvalmistajat.map(function(g, idx) {
            return(<li key={idx}>{g}</li>)
          })}</ul>
          <hr />
          <div className="relatedCasino">
            <h2>Voisit olla kiinnostunut myös...</h2>
            {similar.edges.slice(0, 3).map(edge =>(
              <div key={edge.node.uri} className="card">
                <a href={`/kokemuksia/${edge.node.slug}/`}>
                <img className="cardImg lazyload" src={edge.node.ACFKasino.logo.mediaItemUrl} alt={edge.node.ACFKasino.logo.title} width="310" height="100"/>
                <div className="cardBg"></div>
                <div className="infos">
                  <h4>{edge.node.ACFKasino.kasinonNimi}</h4>
                  <p className="tahti">
                    {edge.node.ACFKasino.arvostelu === 1 && <img src="/images/star1.png" alt='Arvostelu 1/5' width="21" height="20"/>}
                    {edge.node.ACFKasino.arvostelu === 2 && <img src="/images/star2.png" alt='Arvostelu 2/5' width="42" height="20"/>}
                    {edge.node.ACFKasino.arvostelu === 3 && <img src="/images/star3.png" alt='Arvostelu 3/5' width="63" height="20"/>}
                    {edge.node.ACFKasino.arvostelu === 4 && <img src="/images/star4.png" alt='Arvostelu 4/5' width="84" height="20"/>}
                    {edge.node.ACFKasino.arvostelu === 5 && <img src="/images/star5.png" alt='Arvostelu 5/5' width="105" height="20"/>}
                    {edge.node.ACFKasino.arvostelu == null && <span>-</span>}
                  </p>
                  <div className="bonusBlock">
                    <i>Bonus vedonlyöntiin:</i>
                    <div>{edge.node.ACFKasino.bonusVedonloyntiin}</div>
                  </div>
                </div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="fixedTop fixed" id="fixed">
          <div className="rowTop row" id="rowTop">
            <div className="data">
              <img src={kasino.ACFKasino.logo.mediaItemUrl} alt={kasino.ACFKasino.logo.title} width="100" height="42"/>
              {kasino.ACFKasino.kasinoSuljettu == true 
              ?<div className="btn closed">Kasino suljettu</div>
              :<a className="btn" href={kasino.ACFKasino.affiliateVedonlyonti} target="_blank" rel="noopener noreferrer" itemProp="url"><span>Sivustolle!</span></a> 
              }
            </div>
            <div className="bonus">
              <i>Bonus vedonlyöntiin:</i>  
              <p>{kasino.ACFKasino.bonusVedonloyntiin}</p>
            </div>     
          </div>
        </div> 
      </article>
    </Layout>
  )
}
}

export default KasinoTemplate

export const query = graphql`
  query kasino($slug: String!) {
    kasino: wpCptKasino (slug: { eq: $slug }) {
      id
      databaseId
      title
      slug
      content
      seo {
        canonical
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        title
      }
      ACFKasino {
        kasinonNimi
        logo {
          title
          mediaItemUrl
        }
        affiliateCasinobonus
        affiliateVedonlyonti
        arvostelu
        bonusKasino
        bonusVedonloyntiin
        ilmaiskierrokset
        lyhytbonuskuvaus
        plussat
        saannot
        saantoteksti
        vedonlyontibonuskuvaus
        vedonlyontiCtabtn
        vedonlyontiCtateksti
        casinobonuskuvaus
        casinobonusCtabtn
        casinobonusCtateksti
        omistaja
		    perustamisvuosi
        kasinonEmail
        kasinonPuhelin
        kotisivut
        lisenssi
        livechat
        sivutSuomeksi
        suomiKampanjat
        asiakaspalveluSuomeksi
        arvosteluteksti
        arvostelijanKokemukset
        screenshot {
          title
          mediaItemUrl
        }
        pelinvalmistajat
        talletus
        tuotevalikoima
        faq1
        faq2
        faq3
        faq4
        faq5
        faq6
        kasinoSuljettu
      }
    }
    allWpCptKasino(filter: {ACFKasino: {julkaiseArvostelu: {eq: true}, kasinoSuljettu: {ne: true}}, slug: {ne: $slug}}, sort: {order: ASC, fields: ACFKasino___julkaisupaiva}) {
      edges {
        node {
          id
          uri
          slug
          ACFKasino {
            affiliateCasinobonus
            affiliateVedonlyonti
            arvostelu
            bonusKasino
            bonusVedonloyntiin
            ilmaiskierrokset
            julkaiseArvostelu
            kasinonNimi
            logo {
              mediaItemUrl
              title
            }
          }
        }
      }
    }
  }
`
